<template>
  <span class="ty-icon" :class="(classes !== undefined ? classes : '') + ` icon-${icon}`" role="img" :style="style()"></span>
</template>

<script setup lang="ts">
const props = defineProps({
  icon: String,
  folder: String,
  classes: String,
  color: String,
  stroke: String,
  stroke_width: String,
  width: String,
  height: String
})

const style = () => {
  let style = "";
  if (props.color) {
    style += `color: ${props.color}; `;
  }
  if (props.width) {
    style += `font-size: ${props.width} !important; `;
  }
  if (props.stroke_width) {
    style += `font-weight: ${props.stroke_width}; `;
  }
  return style;
}
</script>
